#percentageBoxContainer {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  overflow:hidden;
}

#percentageBoxPaper {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 1.2s linear;
}

#percentageBox {
  display: grid;
  grid-template-areas: 'a a' 'b c';
  background-color: rgba(255,255,255,.95);
  border: 3px solid #aaaaaa;
  grid-gap: .5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  margin: 8px;
}
#percentageBox-current-percent {
  color: black;
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  grid-area: a;
  text-align: center;
}
#percentageBox button {
  background-color: rgba(255,255,255,.2);
  font-size: 0.9rem;
  font-family: 'Libre Baskerville', serif;
  padding: .3rem;
  border: 1px dashed white;
  color:white;
}
#percentageBox button.active {
  border: 1px solid black;
  color: black;
}