@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Source+Sans+Pro:300,300i,400,400i,600');

html {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}


/** Typography
 *
 */

h1, h2, .callout {
  font-family: 'Libre Baskerville', serif;
}

h1 {
  font-size: 2.27rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: var(--app-primary-text);
  pointer-events: all;
}
h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  pointer-events: all;
  margin: 1rem 0;
}
h3 {
  font-family: 'Source Sans Pro';
  font-size: 1.6rem;
  /* text-transform: uppercase; */
  font-weight: 300;
  font-style: italic;
  color: var(--app-primary-text);
  margin: 4.20rem 0 1.15rem;
  letter-spacing: 1px;
}
h3:first-child {
  margin-top: 1rem;
}
h4 {
  font-weight: bold;
  font-size: 1.75rem;
  font-weight: 500;
  margin: 1.5rem 0;
  font-family: 'Source Sans Pro', sans-serif;
}

h2 > a, h3 > a {
  color: inherit;
  text-decoration: none;
  pointer-events: all;
}

a:hover {
  text-decoration: underline;
}

h1,h2,h3,h4,li,p,span,form,button{
  pointer-events: all;
}

button {
  cursor: pointer;
  font-family: 'libre Baskerville', serif;
}

button.text {
  background: none;
  border: none;
  text-decoration: underline;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0;
}

.big-number {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  font-family: 'Libre Baskerville', serif;
  pointer-events: all;
}

#goalsNav {
  display: flex;
  flex-direction: column;
  text-align: right;
  border-top: 1px solid black;
  border-right: 1px solid black;
  height: 100%;
  width: 100%;
}
#goalsNav a {
  margin: 1rem 3rem 1rem;
}
#edit {
  width: 2rem;
  margin: .6rem;
}

p {
  margin: 0.5rem 0 1.5rem;
}
span.example {
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 700px) {
  html {
    font-size:18px;
  }
}


/** Layout **/
.container {
  display: flex;
  flex-direction: row-reverse;
  min-height: 100%;
}

.content {
  flex-grow: 1;
}

@media only screen and (max-width:800px) {
  .content-container {
    width: 98%;
  }
}
