.goalListItem {
  position: relative;
  margin: 1.5rem 0;
  padding-bottom: 0.8rem;
}
.goalListItem.expanded {
  margin-bottom: 2.5rem;
  padding-bottom: 0.2rem;
}
.goalListItem.completed {
  display: none;
}
.goal-grid {
  display: grid;
  grid-template-columns: 6rem auto;
  column-gap: 2rem;
  row-gap: .25rem;
  grid-template-areas: 'left right'
}
.goal-grid.multi {
  grid-template-areas: 'left-top right-top' 'left-bottom right-bottom';
}
.goal-grid.activity{
  color: #a9a9a9;
}
.grid-left {
  grid-area: left;
  text-align: right;
}
.grid-right {
  grid-area: right;
  text-align: left;
}

ul.activity-list {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0.8rem 0 0;
}
ul.activity-list:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #E7E7E7;
  left: 0.5rem;
  top: 20px;
  bottom: 18px;
  width: 1px;
}
.activity-list li {
  display: block;
  padding-left: 26px;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  position:relative;
  color: #A9A9A9;
}
.activity-list li.top-actions, .activity-list li span.empty {
  color: #E7E7E7;
}
.activity-list li.top-actions button {
  color: #A9A9A9;
}

.activity-list li.activity {
  position: relative;
}
.activity-list li.activity:before {
  content: '';
  display: block;
  height: 9px;
  width: 9px;
  border-radius: 5px;
  position: absolute;
  left: 6px;
  top: calc(50% - 4px);
  background-color: #E7E7E7;
}

.activity-list li.activity .tick-date {
  font-family: 'libre Baskerville', serif;
  position: absolute;
  right: calc(100% + 2rem);
  top: 3px;
  font-size: 0.7rem;
}

.dot-and-description {
  display: flex;
  flex-direction: row;
}
.dot-and-description img {
  padding: 0 1rem 0 0;
}
.ticks {
  text-align: right;
  font-size: .66rem;
  align-self: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-self: end;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.grid-left-top {
  grid-area: left-top;
  text-align: right;
}
[class^="grid-right-"] {
  margin-right: 1rem;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.grid-right-top {
  grid-area: right-top;
}
.grid-right-bottom {
  grid-area: right-bottom;
  font-size: 0.84rem;
}
.grid-left-bottom {
  grid-area: left-bottom;
  text-align: right;
}
h2{
  margin-left: 8rem;
  padding: 2rem 0 1rem;
}
.title {
  font-weight: bold;
}
.tick {
  height: .5rem;
  width: .5rem;
  background-color: #a9a9a9;
  border-radius: 50%;
  display: inline-block;
  margin: .15rem;
}
#addGoal-modal {
  position: fixed;
  bottom: 5rem;
  right: 12rem;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  right: 14rem;
  color: #a9a9a9
}
#modal-left{
  display: flex;
  flex-direction: column;
}
#modal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
#modal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 16rem 5rem;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin: .5rem
}
#modal-right input {
  width: 100%
}
#new-goal-description-input-div {
  grid-area: c;
}
#new-goal-description {
  border-bottom: 1px solid #a9a9a9;
}
#new-goal-due-date-input-div {
  text-align: right
}
#new-goal-title {
  border-bottom: 1px solid #a9a9a9;
}
#modal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.date-input {
  text-align: right;
  display: block;
}
.date-input input {
  padding: 4px;
  border-radius: 2px;
  background-color: #f1f1f1;
  width: 100%;
  text-align: right;
}
.plussign {
  border-bottom: 1px solid #a9a9a9;
  height: auto
}
.commit {
  border-bottom: 1px solid #a9a9a9;
  height: auto;
}
.paper-airplane {
  width: 55%;
  height: auto;
}
.editGoal {
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  color: #a9a9a9;
  width: 88%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: 0.4rem;
}
.editGoal-left{
  display: flex;
  flex-direction: column;
  width: 3.5rem
}
.editGoal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
.editGoal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  margin: 1rem;
  
}
.editGoal-right input {
  width: 100%
}
.edit-goal-due-date-input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* text-align: right; */
}
.edit-goal-description-input-div {
  grid-area: c;
}

.edit-goal-title, .edit-goal-description {
  background-color: #f1f1f1;
  width: 100%;
  padding: 4px;
  border-radius: 2px;
}

#editGoal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.goal-button {
  width: auto;
  height: auto;
  padding: .25rem 2rem;
  background-color: #e7e7e7;
  margin-left: 2rem;
  font-size: 1.2rem;
  border-radius: .3rem;
}

.buttons {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
}
.buttons > button {
  position: absolute;
  padding: 0;
  bottom: 0;
}
.buttons button {
  font-size: 0.8rem;
  color: #111a3d;
  font-family: 'Source Sans Pro', sans-serif;
  display:flex;
}
button.button-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding: 0;
}
button.button-image > div{
  width: 30px;
  height: 30px;
  background-color: #E7E7E7;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
  border-radius: 3px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.button-image > span{
  margin-left: 7px;
}
button.button-image > div > img{
  width: 20px;
  height: 20px;
}
.buttons button.disabled {
  color: lightgray;
  cursor: not-allowed;
}
.buttons > .edit-button {
  top: 0.6rem;
  bottom: unset;
}
.buttons > button > img {
  padding: 7px;
}
.expand-button {
  border-radius: 0 0 4px 0;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: none;
  border-top: none;
}
.goal {
  position: relative;
}
.goal-container {
  padding-bottom: 5rem;
  max-width: 650px;
  margin: auto;
}
.dot-and-description {

}

span.placeholder {
  color: #a9a9a9;
}