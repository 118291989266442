.wrapper {
  flex-grow: 0;
  position: relative;
  overflow: visible;
  height: 100vh;
  min-width: 140px;
}

#nav-container {
  text-align: right;
  padding-bottom: 1rem;
  background-color: white;
}

nav ul {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0;
  z-index: 2;
}
nav li#today-link {
  padding-top: 1.5rem;
  margin-bottom: 1.7rem;
}
nav li a {
  display: block;
  margin-top: 0.3rem;
  padding: 0.3rem 40px 0.3rem 0;
  text-decoration: none;
  color: #111a3d;
}
nav li#today-link a {
  color: white;
}
nav li a.active {
  border-right: 18px solid #111a3d;
  padding-right: 22px;
  text-decoration: underline;
}
nav li#today-link a.active {
}

nav button {
  padding-right: 40px;
  text-transform: lowercase;
  font-size: 0.8rem;
  margin-top: 1.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}


#today-blob-container {
  position: absolute;
  pointer-events: all;
  width: 140%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1;
}