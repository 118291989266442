#PercentagePaperBackground {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  overflow:hidden;
}

#paperCanvas {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 1.2s linear;
}