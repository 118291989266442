#today-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#percent {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  z-index: 2;
}
#percentages {
  display: grid;
  grid-template-areas: 'a a' 'b c';
  background-color: rgba(255,255,255,.9);
  border: 3px solid #aaaaaa;
  grid-gap: .5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
#current-percent {
  color: black;
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  grid-area: a;
  text-align: center;
}
#percentages button {
  background-color: rgba(255,255,255,.2);
  font-size: 0.9rem;
  font-family: 'Libre Baskerville', serif;
  padding: .3rem;
  border: 1px dashed #aaaaaa;
  color: #aaaaaa;
}
#percentages button.active {
  border: 1px solid black;
  color: black;
}
#days-of-week {
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#days-of-week > div {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

div.heading {
  margin-top: 1rem;
  color: #aaa;
}
div.heading.today {
  color: black;
  background-color: rgba(255,255,255,0.9);
}
div.heading span {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-family: 'Libre Baskerville', serif;
}

div.stats > div{
  margin: 1.8rem 0 2rem;
}
div.stats > div:last-child {
  margin-top: 5rem;
}

div.stats p {
  font-size: 1.4rem;
  margin: 0.3rem 0 0;
  /* font-family: 'Source Sans Pro', sans-serif; */
}

@media only screen and (max-width: 800px) {
  #days-of-week {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #days-of-week > div:nth-child(1) {
    display: none;
  }
}